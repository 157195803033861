
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































.point-contact {
  position: relative;
  cursor: pointer;

  &__image {
    display: block;

    @media screen and (max-width: 1200px) {
      width: 30px;
      height: auto;
      margin-bottom: 0.5em;
    }
  }

  &__title {
    color: $c-orange;
    font-weight: 600;
  }

  &__subtitle {
    display: flex;
  }

  p + p {
    margin-top: 0;
  }

  &__arrow {
    display: inline-block;
    background-color: $c-orange;
    border-radius: 25px;
    width: 40px;
    height: 40px;
    margin-top: -15px;
    margin-left: 15px;

    :hover {
      cursor: pointer;
      border-radius: 25px;
      background-color: darken($c-orange, 3);
    }

    svg {
      fill: $c-white;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    overflow: hidden;
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: center url('../../../assets/images/icons/chat.svg') no-repeat,
      #fff;
    z-index: 100;
    transition: 0.3s;
    cursor: pointer;
    padding: 30px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 28%);

    > * {
      opacity: 0;
      transition: 0.3s;
    }

    &.active {
      display: none;
    }
  }
}
