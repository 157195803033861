
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































































.panel-contact {
  h2.h1 {
    font-size: 4rem;

    .outline {
      font-size: 4rem;
    }
  }

  h3.h3 {
    font-size: 2.2rem;
  }

  &__list__separator {
    background-color: $c-gray-separator;
  }

  &__list__small__separator {
    width: 65%;
    background-color: transparent;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px dashed $c-gray-separator;
  }

  &__list__contact {
    display: flex;

    &__image {
      margin-right: 3rem;

      &__img {
        border-radius: 50%;
        width: 65px;
        height: 65px;
      }
    }

    &__infos {
      p + p {
        margin-top: 1rem;
      }
    }
  }
}
