
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















.success {
  background-color: $c-bg-success;
  padding: 25px;
  margin-bottom: 30px;
  color: $c-green;
  border-radius: 20px;

  ::v-deep {
    .close-link {
      float: right;
      cursor: pointer;
    }

    svg {
      fill: $c-green;
    }
  }
}
.error {
  background-color: $c-bg-error;
  padding: 25px;
  margin-bottom: 30px;
  color: $c-red;
  border-radius: 20px;

  ::v-deep {
    .close-link {
      float: right;
      cursor: pointer;
    }

    svg {
      fill: $c-red;
    }
  }
}
.warning {
  background-color: $c-bg-error;
  padding: 25px;
  margin-bottom: 30px;
  color: $c-lemon;
  border-radius: 20px;

  ::v-deep {
    .close-link {
      float: right;
      cursor: pointer;
    }

    svg {
      fill: $c-lemon;
    }
  }
}
