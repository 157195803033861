
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














































































































































.vgt-wrap table.vgt-table {
  background: #ffffff;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #eaeaea;
  /* Elevation / 05 */

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.vgt-wrap table.vgt-table tbody td.adresse {
  min-width: 20vw;
}

.vgt-wrap table.vgt-table tbody td .vgt-actions {
  display: flex;
  gap: 20px;

  @media screen and (max-width: 767px) {
    gap: 5px;
  }
}

.vgt-wrap table.vgt-table tbody td .vgt-actions .vgt-btn {
  position: relative;
  padding: 8px;
  margin-bottom: 0;

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
    background: none;
  }
}

.facets {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-bottom: 4rem;

  @include mq(m) {
    flex-flow: row wrap;
  }

  &__search {
    width: 100%;
    margin-bottom: 2rem;
    @include mq(m) {
      max-width: 41.9rem;
      margin-bottom: unset;
    }

    .input-wrapper {
      position: relative;

      &:after {
        content: url('../../../assets/svg/commune/search.svg');
        width: 1.9rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        top: calc(50% - 1rem);
        transition: opacity 0.3s ease-in-out;
      }

      &:focus-within:after {
        opacity: 0.5;
      }
    }

    input.search {
      width: 100%;
      border: 0;
      background-color: $c-gray-lightest;
      border-radius: 0.5rem;
      height: 5.5rem;
      padding: 1rem 2rem;

      &:focus {
        border: 1px solid $c-gray-darker;
        background-color: $c-white;
      }
    }
  }

  &__filters {
    font-size: 1.4rem;
    display: flex;
    flex-flow: column;
    margin-left: auto;
    width: 100%;

    @include mq(m) {
      display: flex;
      flex-flow: row wrap;
      width: auto;
    }

    &__label {
      display: flex;
      align-items: center;
      color: $c-main-primary;
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include mq(m) {
        margin-bottom: unset;
      }

      > svg {
        margin-right: 0.6rem;
        fill: $c-main-primary;
      }
    }
  }

  .select-box {
    @include mq(m) {
      margin-left: 0.5rem;
      margin-bottom: unset;
    }
    flex-flow: row wrap;
    margin-bottom: 1rem;
  }
}
