
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




































































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.page-header-image {
  max-width: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 4rem;
  }
}

.page-header {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @include mq(m) {
    flex-direction: row;
    align-items: flex-start;
  }

  &__title {
    h1.main-title {
      display: block;

      @include mq(m) {
        display: inline-block;
      }
    }

    span.subtitle {
      display: inline-block;
      margin-left: 0;
      font-size: 1.4rem;
      @include mq(m) {
        margin-left: 1.5rem;
      }
    }
  }

  &__actions {
    margin-left: auto;
    flex: 0 0 auto;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 767px) {
      width: 100%;
      align-items: stretch;
      margin-top: 30px;
      margin-bottom: 30px;

      a {
        width: 100%;
      }
    }
  }
}

::v-deep .vgt-responsive {
  overflow-x: hidden;
}

::v-deep .vgt-table {
  th,
  td {
    &.status {
      display: none;
      @include mq(s) {
        display: table-cell;
      }
    }

    .vgt-actions {
      width: 100% !important;
    }
  }

  tr {
    height: 60px;
  }

  thead {
    th {
      width: 20rem !important;

      &.sortable {
        button {
          &:after,
          &:before {
            display: none;
          }
        }
      }

      &.actions {
        min-width: 10rem !important;
        width: 10rem !important;
      }

      &.statut {
        width: 15rem !important;
      }
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  transition: all 0.3s ease-in-out;

  &--green--white {
    color: $c-white;
    background-color: $c-green;
    border: 1px solid $c-green;

    > svg {
      fill: $c-white;
    }

    &:hover {
      background-color: $c-white;
      color: $c-green;

      > svg {
        fill: $c-green;
      }
    }
  }

  &--round {
    border-radius: 5rem;
  }
}
